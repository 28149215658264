<template>
  <b-card v-if="data.subscribersGained" no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="8" class="revenue-report-wrapper">
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h4 class="card-title mb-50 mb-sm-0">
            Atividades
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span
                class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Realizadas</span>
            </div>
            <div v-if="false" class="d-flex align-items-center ml-75">
              <span
                class="bullet bullet-primary svg-font-small-3 mr-50 cursor-pointer"
              />
              <span>Faltas/Canceladas</span>
            </div>
          </div>
        </div>

        <!-- chart -->
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="data.subscribersGained.series"
        />
      </b-col>

      <b-col md="4" class="budget-wrapper">
        <b-dropdown
          :text="year.toString()"
          size="sm"
          class="budget-dropdown"
          variant="outline-primary"
        >
          <b-dropdown-item
            v-for="years in data.subscribersGained.years"
            :key="years"
            @click="$emit('update:year', years)"
            :active="years == year"
          >
            {{ years }}
          </b-dropdown-item>
        </b-dropdown>

        <h2 class="mb-25">
          <i-count-up :delay="500" :endVal="priceTotal" :options="options" />
        </h2>
        <div class="d-flex justify-content-center" v-if="false">
          <span class="font-weight-bolder mr-25">Budget:</span>
          <span>56,800</span>
        </div>
        <vue-apex-charts
          id="budget-chart"
          type="line"
          height="80"
          :options="budgetChart.options"
          :series="data.subscribersGained.budgetChart.series"
        />

        <b-button
          :to="{ name: 'forca-calendar' }"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
        >
          Abrir agenda
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import ICountUp from "vue-countup-v2";

export default {
  components: {
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    ICountUp,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    meses: {
      type: Array,
      default: () => [],
    },
    year: {
      type: Number,
    },
  },
  watch: {
    meses(val) {
      this.$set(this.revenueReport.chartOptions.xaxis, "categories", val);
    },
  },

  computed: {
    priceTotal() {
      return this.data.subscribersGained.price;
    },
  },

  data() {
    return {
      options: {
        useEasing: true,
        formattingFn: (n) => {
          return `R$ ${this.$options.filters.formatMoney(n)}`;
        },
      },
      revenue_report: {},
      revenueReport: {
        chartOptions: {
          chart: {
            stacked: true,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: false },
            },
          },
          xaxis: {
            categories: this.meses,
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.success],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
      },
      // budget chart
      budgetChart: {
        options: {
          chart: {
            height: 80,
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            sparkline: { enabled: true },
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 5],
            width: [2],
          },
          colors: [$themeColors.info, "#dcdae3"],
          tooltip: {
            enabled: false,
          },
        },
      },
    };
  },
};
</script>
